@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@charset "UTF-8";
.dashImageButtons {
  height: 100%;
  width: 100%;
  -webkit-filter: drop-shadow(0px 1px 2px #979797);
  filter: drop-shadow(0px 1px 2px #979797);
  transition: 0.2s;
}

.dashImageButtons:hover {
  -webkit-filter: drop-shadow(2px 3px 4px #888888);
  filter: drop-shadow(2px 3px 4px #888888);
}

.dashboard-buttons-container {
  float: right;
  margin-right: 20px;
}

.dashButtons {
  background-color: Transparent;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  width: 50%;
  padding: 3px 4px 4px 3px;
}

.org-count-wrapper {
  background-color: #316ea9;
  color: #fff;
  padding: 30px 5px;
  text-align: center;
  border-radius: 10px;
  max-height: 130px;
  height: 120px;
  line-height: 15px;
  width: 100%;
  max-width: 230px;
  margin: 0 auto;
  -webkit-filter: drop-shadow(0px 1px 2px #979797);
  filter: drop-shadow(0px 1px 2px #979797);
}

.wave1 {
  background-image: url(/static/media/wave1.9c152a33.svg);
  background-repeat: no-repeat;
  background-position: 0% 110%;
}

.wave2 {
  background-image: url(/static/media/wave2.21e77975.svg);
  background-repeat: no-repeat;
  background-position: top;
}

.wave3 {
  background-image: url(/static/media/wave3.3a3e51b2.svg);
  background-repeat: no-repeat;
  background-position: 0% 110%;
}

.wave4 {
  background-image: url(/static/media/wave4.f67271a5.svg), url(/static/media/wave42.bbe44ce6.svg);
  background-repeat: no-repeat, no-repeat;
  background-position: top, 0% 110%;
  background-size: 100%, 60%;
}

.wave5 {
  background-image: url(/static/media/wave5.34d1b1ee.svg), url(/static/media/wave52.263f6156.svg);
  background-repeat: no-repeat, no-repeat;
  background-position: top right, 0% 105%;
  background-size: 20%, 100%;
}

.anal-component-wrapper {
  background-color: #fff;
  border-radius: 20px;
  margin-left: 8%;
  padding: 10px;
  height: 330px;
  box-shadow: 8px 8px 8px rgba(49, 110, 169, 0.1);
}

.dropdown-padding {
  padding-top: 9%;
}

.dashboard-more {
  display: block;
  background-color: #386895 !important;
  color: #fff !important;
  padding: 10px 0px !important;
  width: 160px;
  border-radius: 10px !important;
  margin: 0 auto !important;
}

.component-wrapper {
  background-color: #fff;
  border-radius: 20px;
  padding: 10px;
  width: 500px;
  height: 150px;
  margin-bottom: 10px;
  box-shadow: 8px 8px 8px rgba(49, 110, 169, 0.1);
}

.row-spacing {
  margin-top: 8%;
}

.dropdown-padding {
  padding-top: 9%;
}

.dashboard-more {
  display: block;
  background-color: #386895 !important;
  color: #fff !important;
  padding: 10px 0px !important;
  width: 160px;
  border-radius: 10px !important;
  margin: 0 auto !important;
}

.org-number-wrapper {
  font-size: 2.5em;
  font-weight: bolder;
  line-height: 50px;
}

.dash-secondary-heading {
  font-size: 17px;
  color: #282b2b;
  text-align: left;
  text-transform: uppercase;
  font-weight: 600;
  padding-top: 5%;
  padding-left: 7%;
  letter-spacing: 1px;
}

.head-row {
  margin-left: 2% !important;
  margin-bottom: 1%;
}

.dash-heading {
  font-size: 20px;
  display: inherit;
  color: #454F63;
  font-weight: 500;
  margin-bottom: 10px;
  margin-left: 5px;
  font-weight: 400;
}

div#dashboardAdmin {
  max-width: 1025px;
}

.head-row {
  margin-left: 2% !important;
  margin-bottom: 1%;
}

.fontawesome-dash {
  margin: 5px 5px 0 0;
  color: #202F81;
}

.dashboard-register-button {
  background-color: #386895 !important;
  color: #fff !important;
  border-radius: 8px !important;
  padding: 5px 10px !important;
  position: relative;
  left: 50%;
  bottom: 30%;
  font-size: 14px;
}

.dash-view-button {
  margin-right: 16px !important;
  border-radius: 8px !important;
  padding: 10px 20px !important;
  color: #386895 !important;
  background-color: transparent !important;
  border: none;
  position: relative;
  left: 70%;
  bottom: 30%;
  font-weight: 500;
  font-size: 14px;
}

#dashRowAdmin {
  margin-left: 3%;
  margin-bottom: 2%;
}

.scrollable-menu {
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
}

.dash-dropdown button {
  border-radius: 4px !important;
  padding: 7px 2px !important;
  color: #386895 !important;
  background-color: transparent !important;
  border: 1px solid #386895 !important;
}

.no-right-pad {
  padding-right: 0px !important;
}

.col-2dot4,
.col-sm-2dot4,
.col-md-2dot4,
.col-lg-2dot4,
.col-xl-2dot4 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-2dot4 {
  -webkit-flex: 0 0 20%;
          flex: 0 0 20%;
  max-width: 20%;
}

@media (min-width: 540px) {
  .col-sm-2dot4 {
    -webkit-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 720px) {
  .col-md-2dot4 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 960px) {
  .col-lg-2dot4 {
    -webkit-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 1140px) {
  .col-xl-2dot4 {
    -webkit-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%;
  }
}

th.table-headers-docs {
  font-weight: 600 !important;
  white-space: nowrap;
  text-align: left !important;
  font-size: 14px;
}

tr {
  line-height: 35px;
}

th {
  min-width: 40px;
}

.table-docs {
  max-height: 220px;
  overflow-y: auto;
}

@media (max-width: 575px) {
  .modal-dialog {
    margin: 1.75rem auto;
    margin-top: 10px;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    margin: 1.75rem auto;
  }
}

.modaldoc-col {
  color: #454f63;
  font-size: 12px;
  font-weight: 500;
  vertical-align: top;
  padding-top: 16px;
  text-align: left !important;
  max-width: 150px;
  text-overflow: ellipsis;
  overflow-x: auto;
}

.modaldoc-col > p {
  margin-left: 20px;
}

#modaldoc-del-icon {
  height: 17px;
}

.modalDoc {
  margin-top: 70px;
}

.loader {
  color: #009fff;
  font-size: 20px;
  margin: 100px auto;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

#sidemenu {
  width: 100%;
  height: 111%;
  box-shadow: 0px 8px 6px #00000029 !important;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  background-color: #fff;
  letter-spacing: normal;
  text-align: left;
  z-index: 1000;
  margin-top: -75px;
}

#sidemenu-hidden {
  display: none;
}

.sidemenu-ul {
  margin-top: 25%;
  width: 100%;
  display: block;
  float: right;
  list-style: none;
  -webkit-padding-start: 19px;
          padding-inline-start: 19px;
}

#sidemenu-ul-register {
  list-style: none;
  width: 100%;
  height: 0vh;
  overflow: hidden;
  transition: height 0.3s ease-out;
  padding-left: 0%;
  border-bottom-left-radius: 15px;
}

.sidemenu-li {
  cursor: pointer;
  background-color: transparent;
  font-size: 0.75em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  width: 100%;
  padding: 7% 2% 6% 4%;
  position: relative;
  color: #454F63;
}

.sidemenu-li:hover {
  background-color: #dde2e7 !important;
  color: #4b5e72 !important;
  border-top-left-radius: 20px 10px;
  border-bottom-left-radius: 20px 10px;
}

#borderRight {
  background: #fed;
  position: relative;
}

.activeMI > li {
  background-color: #dde2e7 !important;
  color: #202F81 !important;
  border-top-left-radius: 20px 10px;
  border-bottom-left-radius: 20px 10px;
}

#sidemenu-col {
  padding: 0%;
  box-shadow: 8px 8px 8px #178BC616;
  z-index: 20000;
}

.fontawesome-sidemenu {
  margin-right: 10%;
  margin-left: 2%;
  color: #202F81;
}

@media only screen and (max-width: 600px) {
  #sidemenu {
    width: 130%;
    height: 100%;
    box-shadow: 0px 8px 6px #00000029 !important;
    font-size: 19px;
    z-index: 1000;
    margin-top: -7px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 767px) {
  #sidemenu {
    width: 100%;
    height: 100%;
    box-shadow: 0px 8px 6px #00000029 !important;
    font-size: 19px;
    z-index: 1000;
    margin-top: -7px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .sidemenu-li {
    font-size: 15px;
  }
}

.header-options {
  margin: 2% 0%;
}

.header-options.col-1 {
  padding-left: 0%;
  padding-right: 0%;
}

.header-options svg,
.header-options a {
  color: #045097;
}

#app-row-header {
  position: relative;
  box-shadow: 8px 8px 8px #0ea9f716 !important;
  z-index: 10000;
  background-color: white;
}

#header-logo {
  margin-left: -19px;
  width: 245px;
  height: auto;
  margin-top: 8px;
  margin-bottom: -35px;
  z-index: 10000;
}

#header-row {
  background-color: #ffffff;
  height: 70px;
}

.fontawesome-header {
  -webkit-filter: drop-shadow(0px 5px 8px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0px 5px 8px rgba(0, 0, 0, 0.3));
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .grid-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -ms-grid-rows: 0.1fr  0.5fr 0.5fr;
        grid-template-rows: 0.1fr  0.5fr 0.5fr;
    grid-gap: 10px 10px;
    gap: 10px 10px;
        grid-template-areas: "Header" "Map" "Notifications" "Customers" "Administrators" "Technicians";
  }
  .Header {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: Header;
    margin-top: 3%;
    margin-left: 6%;
  }
  .Customers {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
    grid-area: Customers;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    padding: 5%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 90%;
    margin-left: 6%;
  }
  .Administrators {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
    grid-area: Administrators;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    padding: 3%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 90%;
    margin-left: 6%;
  }
  .Technicians {
    -ms-grid-row: 6;
    -ms-grid-column: 1;
    grid-area: Technicians;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    padding: 5%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 90%;
    margin-left: 6%;
  }
  .Map {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    grid-area: Map;
    background-color: white;
    border-style: hidden;
    border-radius: 10px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 90%;
    margin-top: -3%;
    margin-left: 6%;
  }
  .Notifications {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    grid-area: Notifications;
    background-color: rgba(255, 255, 255, 0.897);
    border-style: hidden;
    border-radius: 15px;
    height: 100%;
    width: 90%;
    margin-left: 6%;
    box-shadow: 8px 8px 8px #178BC616;
  }
  .notifications-list {
    list-style-type: disc;
    list-style-position: inside;
    padding: 0;
    margin-top: 1px;
    margin-left: 27px;
    margin-right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 230px;
    position: relative;
  }
  .notifications-list li {
    margin: 0;
    padding-top: 2%;
    padding-bottom: 4%;
    font-size: 12px;
    list-style-type: none;
    cursor: pointer;
  }
  .booking-id {
    color: #202F81;
    font-weight: 650;
    margin-right: 2px;
    position: absolute;
  }
  .notification-time {
    color: #343434;
    font-weight: 600;
    margin-left: 220px;
    position: absolute;
    max-width: 103px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .tech-name {
    font-weight: 500;
    color: #454F63;
    overflow: hidden;
    position: absolute;
    max-width: 50px;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 160px;
  }
  .customer-name-notif {
    font-weight: 500;
    color: #454F63;
    margin-left: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    max-width: 111px;
    white-space: nowrap;
  }
  .text-dashboard {
    text-align: left;
    margin-top: 20px;
    font-family: "Roboto";
    font-size: 13px;
    padding-left: 7%;
  }
  .dashboard-register-button {
    background-color: #386895 !important;
    color: #fff !important;
    border-radius: 12px !important;
    position: relative;
    left: 20%;
    bottom: 0%;
    font-size: 12px;
    border: 2px solid #386895;
  }
  .dash-view-button {
    margin-right: 16px !important;
    border-radius: 8px !important;
    color: #386895 !important;
    background-color: transparent !important;
    border: none;
    position: relative;
    left: 30%;
    bottom: 0%;
    font-weight: 500;
    font-size: 12px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 767px) {
  .grid-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -ms-grid-rows: 0.1fr 0.5fr 0.5fr;
        grid-template-rows: 0.1fr 0.5fr 0.5fr;
    grid-gap: 10px 1px;
    gap: 10px 1px;
        grid-template-areas: "Header" "Map" "Notifications" "Customers" "Administrators" "Technicians";
  }
  .Header {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: Header;
    margin-top: 2%;
    margin-left: 14%;
  }
  .Customers {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
    grid-area: Customers;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    padding: 1%;
    width: 75%;
    margin-left: 14%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .Administrators {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
    grid-area: Administrators;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    padding: 1%;
    height: 100%;
    width: 75%;
    margin-left: 14%;
  }
  .Technicians {
    -ms-grid-row: 6;
    -ms-grid-column: 1;
    grid-area: Technicians;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    padding: 1%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 75%;
    margin-left: 14%;
  }
  .Map {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    grid-area: Map;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    width: 75%;
    margin-left: 14%;
  }
  .Notifications {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    grid-area: Notifications;
    background-color: rgba(255, 255, 255, 0.897);
    border-style: hidden;
    border-radius: 15px;
    height: 100%;
    width: 75%;
    margin-left: 14%;
  }
  .notifications-list {
    list-style-type: disc;
    list-style-position: inside;
    padding: 0;
    margin-top: 9px;
    margin-left: 27px;
    margin-right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 320px;
    position: relative;
  }
  .notifications-list li {
    margin: 0;
    padding-top: 2%;
    padding-bottom: 5%;
    font-size: 12px;
    list-style-type: none;
    cursor: pointer;
  }
  .notification-time {
    color: #343434;
    font-weight: 600;
    margin-left: 300px;
    position: absolute;
    max-width: 103px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .booking-id {
    color: #202F81;
    font-weight: 650;
    margin-right: 2px;
    position: absolute;
  }
  .tech-name {
    font-weight: 500;
    color: #454F63;
    position: absolute;
    max-width: 110px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 75px;
  }
  .customer-name-notif {
    font-weight: 500;
    color: #454F63;
    margin-left: 180px;
    position: absolute;
    max-width: 111px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .text-dashboard {
    text-align: left;
    margin-top: 0px;
    font-family: "Roboto";
    font-size: 14px;
    padding-left: 7%;
  }
  .dash-secondary-heading {
    font-size: 16px;
    color: #454F63;
    text-align: left;
    text-transform: uppercase;
    font-weight: 600;
    padding-top: 1%;
    padding-left: 7%;
    letter-spacing: 1px;
  }
  .dashboard-register-button {
    background-color: #386895 !important;
    color: #fff !important;
    border-radius: 12px !important;
    position: relative;
    left: 20%;
    bottom: 0%;
    font-size: 12px;
    border: 2px solid #386895;
  }
  .dash-view-button {
    margin-right: 16px !important;
    border-radius: 8px !important;
    color: #386895 !important;
    background-color: transparent !important;
    border: none;
    position: relative;
    left: 30%;
    bottom: 0%;
    font-weight: 500;
    font-size: 12px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grid-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    -ms-grid-rows: 0.1fr 0.5fr 0.5fr 0.5fr;
        grid-template-rows: 0.1fr 0.5fr 0.5fr 0.5fr;
    grid-gap: 15px 20px;
    gap: 15px 20px;
        grid-template-areas: "Header Header" "Map Map" "Notifications Customers" "Notifications Administrators" "Notifications Technicians";
    margin: 30px 30px;
  }
  .Header {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: Header;
  }
  .Customers {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
    grid-area: Customers;
    background-color: white;
    border-style: hidden;
    border-radius: 25px;
    padding: 5%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: -150px;
  }
  .Administrators {
    -ms-grid-row: 4;
    -ms-grid-column: 2;
    grid-area: Administrators;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    padding: 5%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: -150px;
    margin-top: 17%;
  }
  .Technicians {
    -ms-grid-row: 5;
    -ms-grid-column: 2;
    grid-area: Technicians;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    padding: 5%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 35%;
  }
  .Map {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: Map;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    box-shadow: 5px 5px 10px #405f051a;
  }
  .Notifications {
    -ms-grid-row: 3;
    -ms-grid-row-span: 3;
    -ms-grid-column: 1;
    grid-area: Notifications;
    background-color: rgba(255, 255, 255, 0.897);
    border-style: hidden;
    border-radius: 15px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 466px;
    width: 100%;
    min-height: 300px;
    overflow-y: auto;
    position: relative;
  }
  .notifications-list {
    list-style-type: disc;
    list-style-position: inside;
    padding: 0;
    margin-top: 9px;
    margin-left: 27px;
    margin-right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 440px;
  }
  .notifications-list li {
    margin: 0;
    padding-top: 2%;
    padding-bottom: 5%;
    font-size: 13px;
    list-style-type: none;
    cursor: pointer;
  }
  .notification-time {
    color: #343434;
    font-weight: 600;
    margin-left: 220px;
    position: absolute;
    white-space: nowrap;
  }
  .tech-name {
    font-weight: 500;
    color: #454F63;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    margin-left: 44px;
    max-width: 100px;
  }
  .customer-name-notif {
    font-weight: 500;
    color: #454F63;
    margin-left: 155px;
    position: absolute;
    max-width: 60px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .booking-id {
    color: #202F81;
    font-weight: 650;
    margin-right: 10px;
    position: absolute;
  }
  .text-dashboard {
    text-align: left;
    margin-top: 20px;
    font-family: "Roboto";
    font-size: 13px;
    padding-left: 7%;
  }
  .dashboard-register-button {
    background-color: #386895 !important;
    color: #fff !important;
    border-radius: 12px !important;
    padding: 5px 10px !important;
    position: relative;
    left: 20%;
    bottom: 30%;
    font-size: 12px;
    border: 2px solid #386895;
  }
  .dash-view-button {
    margin-right: 16px !important;
    border-radius: 8px !important;
    padding: 5px 10px !important;
    color: #386895 !important;
    background-color: transparent !important;
    border: none;
    position: relative;
    left: 30%;
    bottom: 30%;
    font-weight: 500;
    font-size: 13px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1100px) {
  .grid-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: 0.1fr 0.6fr 0.4fr;
        grid-template-rows: 0.1fr 0.6fr 0.4fr;
    grid-gap: 9px 9px;
    gap: 9px 9px;
        grid-template-areas: "Header Header Header"  "Map Map Map" "Notifications Notifications Notifications"  "Customers Administrators Technicians";
    margin-right: 50px;
    margin-left: 50px;
  }
  .Header {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-area: Header;
    margin-top: -1%;
    margin-left: 6%;
  }
  .Customers {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
    grid-area: Customers;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    padding: 5%;
    height: 100%;
    width: 100%;
  }
  .Administrators {
    -ms-grid-row: 4;
    -ms-grid-column: 2;
    grid-area: Administrators;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    padding: 5%;
    height: 100%;
    width: 100%;
  }
  .Technicians {
    -ms-grid-row: 4;
    -ms-grid-column: 3;
    grid-area: Technicians;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    padding: 5%;
    height: 100%;
    width: 100%;
  }
  .Map {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-area: Map;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 90%;
    margin-left: 6%;
  }
  .Notifications {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-area: Notifications;
    background-color: rgba(255, 255, 255, 0.897);
    border-style: hidden;
    border-radius: 15px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 90%;
    box-shadow: 8px 8px 8px #178BC616;
    margin-left: 40px;
  }
  .notifications-list {
    list-style-type: disc;
    list-style-position: inside;
    padding: 0;
    margin-top: 9px;
    margin-left: 67px;
    margin-right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 100px !important;
    max-height: 270px;
    position: relative;
  }
  .notifications-list li {
    margin: 0;
    padding-top: 2%;
    padding-bottom: 4%;
    font-size: 13px;
    list-style-type: none;
    cursor: pointer;
  }
  .booking-id {
    color: #202F81;
    font-weight: 650;
    position: absolute;
  }
  .notification-time {
    color: #343434;
    font-weight: 600;
    margin-left: 390px;
    position: absolute;
    white-space: nowrap;
  }
  .tech-name {
    font-weight: 500;
    color: #454F63;
    position: absolute;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 75px;
  }
  .customer-name-notif {
    font-weight: 500;
    color: #454F63;
    margin-left: 210px;
    position: absolute;
    max-width: 190px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .dashboard-register-button {
    background-color: #386895 !important;
    color: #fff !important;
    border-radius: 8px !important;
    padding: 5px 10px !important;
    position: relative;
    left: 20%;
    bottom: 30%;
    font-size: 11px;
    border: 2px solid #386895;
  }
  .dash-view-button {
    margin-right: 16px !important;
    border-radius: 8px !important;
    padding: 5px 10px !important;
    color: #386895 !important;
    background-color: transparent !important;
    border: none;
    position: relative;
    left: 30%;
    bottom: 30%;
    font-weight: 500;
    font-size: 11px;
  }
  .text-dashboard {
    text-align: left;
    margin-top: 10px;
    font-family: "Roboto";
    font-size: 12px;
    padding-left: 7%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1100px) {
  .grid-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1.1fr 1fr 1fr;
        grid-template-columns: 1.1fr 1fr 1fr;
    -ms-grid-rows: 0.1fr 1.6fr 0.7fr;
        grid-template-rows: 0.1fr 1.6fr 0.7fr;
    grid-gap: 30px 20px;
    gap: 30px 20px;
        grid-template-areas: "Header Header Header " "Notifications Map Map " "Technicians Customers Administrators ";
  }
  .Header {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-area: Header;
    margin: 0;
    padding: 0;
    margin-top: -20px;
    margin-left: 40px;
  }
  .Customers {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
    grid-area: Customers;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    height: 100%;
    width: 98%;
    margin: 0;
    padding: 3px;
    box-shadow: 8px 8px 8px #178BC616;
  }
  .Administrators {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
    grid-area: Administrators;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    height: 100%;
    width: 91%;
    margin-left: -8px;
    padding: 0;
    box-shadow: 8px 8px 8px #178BC616;
  }
  .Technicians {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    grid-area: Technicians;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    height: 100%;
    width: 90%;
    margin: 0;
    padding: 0;
    margin-left: 40px;
    box-shadow: 8px 8px 8px #178BC616;
  }
  .Map {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    grid-area: Map;
    background-color: rgba(255, 255, 255, 0.863);
    border-style: hidden;
    border-radius: 25px;
    width: 84%;
    height: 107%;
    margin-left: 70px;
    padding: 0;
    margin-top: -3%;
    margin-right: 44px;
  }
  .Notifications {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    grid-area: Notifications;
    background-color: rgba(255, 255, 255, 0.897);
    border-style: hidden;
    border-radius: 15px;
    height: 108%;
    width: 106%;
    box-shadow: 8px 8px 8px #178BC616;
    margin-top: -7%;
    margin-left: 40px;
  }
  .notifications-list {
    list-style-type: disc;
    list-style-position: inside;
    padding: 0;
    margin-top: 9px;
    margin-left: 27px;
    margin-right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 220px;
    position: relative;
  }
  .notifications-list li {
    margin: 0;
    padding-top: 7%;
    padding-bottom: 7%;
    font-size: 12px;
    list-style-type: none;
  }
  .booking-id {
    color: #202F81;
    font-weight: 650;
    position: absolute;
  }
  .notification-time {
    color: #343434;
    font-weight: 600;
    margin-left: 240px;
    position: absolute;
    max-width: 103px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .tech-name {
    font-weight: 500;
    color: #454F63;
    position: absolute;
    max-width: 75px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 39px;
  }
  .customer-name-notif {
    font-weight: 500;
    color: #454F63;
    margin-left: 110px;
    text-overflow: ellipsis;
    position: absolute;
    max-width: 110px;
    overflow: hidden;
    white-space: nowrap;
  }
  .text-dashboard {
    text-align: left;
    margin-top: 20px;
    font-family: "Roboto";
    font-size: 13px;
    padding-left: 7%;
  }
  .dashboard-register-button {
    background-color: #386895 !important;
    color: #fff !important;
    border-radius: 8px !important;
    padding: 5px 10px !important;
    position: relative;
    left: 20%;
    bottom: 10%;
    font-size: 13px;
    border: 2px solid #386895;
  }
  .dash-view-button {
    margin-right: 16px !important;
    border-radius: 8px !important;
    padding: 5px 10px !important;
    color: #386895 !important;
    background-color: transparent !important;
    border: none;
    position: relative;
    left: 30%;
    bottom: 10%;
    font-weight: 600;
    font-size: 13px;
  }
  #form-color {
    background-color: #e9ebf2;
  }
  .copyright {
    white-space: nowrap;
    margin-bottom: 1%;
    font-size: 14px;
    text-align: center;
    padding-left: 130%;
  }
  .datatable {
    margin: 1%;
  }
  .dash-secondary-heading {
    font-size: 16px;
    color: #454F63;
    text-align: left;
    text-transform: uppercase;
    font-weight: 600;
    padding-top: 4%;
    padding-left: 7%;
    letter-spacing: 1px;
  }
  .list-title {
    font-weight: 500;
    color: #454F63;
  }
  .del-edit-icon {
    margin-right: 15px;
    margin-bottom: 5px;
  }
  #fonticon-list {
    margin-bottom: 25px;
    margin-left: 4%;
    margin-top: -12px;
  }
  #icon-font {
    margin-bottom: 22px;
  }
  .header-lists {
    margin-top: -48px;
    margin-bottom: 18px;
    margin-left: 6%;
    color: #454F63;
    font-weight: 400;
  }
}

@media only screen and (min-width: 1660px) {
  .dashboard-register-button {
    background-color: #386895 !important;
    color: #fff !important;
    border-radius: 8px !important;
    padding: 5px 10px !important;
    position: relative;
    bottom: -80%;
    font-size: 13px;
    border: 2px solid #386895;
  }
  .dash-view-button {
    margin-right: 16px !important;
    border-radius: 8px !important;
    padding: 5px 10px !important;
    color: #386895 !important;
    background-color: transparent !important;
    border: none;
    position: relative;
    bottom: -90%;
    font-weight: 600;
    font-size: 13px;
  }
  .grid-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1.1fr 1fr 1fr;
        grid-template-columns: 1.1fr 1fr 1fr;
    -ms-grid-rows: 0.1fr 1.6fr 0.7fr;
        grid-template-rows: 0.1fr 1.6fr 0.7fr;
    grid-gap: 30px 20px;
    gap: 30px 20px;
        grid-template-areas: "Header Header Header " "Notifications Map Map " "Technicians Customers Administrators ";
  }
  .Header {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-area: Header;
    margin: 0;
    padding: 0;
    margin-top: -20px;
    margin-left: 40px;
  }
  .Customers {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
    grid-area: Customers;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    height: 100%;
    width: 98%;
    margin: 0;
    padding: 3px;
    box-shadow: 8px 8px 8px #178BC616;
  }
  .Administrators {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
    grid-area: Administrators;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    height: 100%;
    width: 91%;
    margin-left: -8px;
    padding: 0;
    box-shadow: 8px 8px 8px #178BC616;
  }
  .Technicians {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    grid-area: Technicians;
    background-color: white;
    border-style: hidden;
    border-radius: 15px;
    height: 100%;
    width: 90%;
    margin: 0;
    padding: 0;
    margin-left: 40px;
    box-shadow: 8px 8px 8px #178BC616;
  }
  .Map {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    grid-area: Map;
    background-color: rgba(255, 255, 255, 0.863);
    border-style: hidden;
    border-radius: 25px;
    width: 84%;
    height: 107%;
    margin-left: 90px;
    padding: 0;
    margin-top: -3%;
    margin-right: 44px;
  }
  .Notifications {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    grid-area: Notifications;
    background-color: rgba(255, 255, 255, 0.897);
    border-style: hidden;
    border-radius: 15px;
    height: 108%;
    width: 105%;
    box-shadow: 8px 8px 8px #178BC616;
    margin-top: -7%;
    margin-left: 40px;
  }
  .notifications-list {
    list-style-type: disc;
    list-style-position: inside;
    padding: 0;
    margin-top: 9px;
    margin-left: 27px;
    margin-right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 320px;
  }
  .notifications-list li {
    margin: 0;
    padding-top: 2%;
    padding-bottom: 5%;
    font-size: 12px;
    list-style-type: none;
    cursor: pointer;
  }
  .notification-time {
    color: #343434;
    font-weight: 600;
    margin-left: 370px;
    position: absolute;
    max-width: 183px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .tech-name {
    font-weight: 500;
    color: #454F63;
    position: absolute;
    max-width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 75px;
  }
  .customer-name-notif {
    font-weight: 500;
    color: #454F63;
    margin-left: 200px;
    position: absolute;
    max-width: 181px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .dashboard-register-button {
    background-color: #386895 !important;
    color: #fff !important;
    border-radius: 12px !important;
    padding: 5px 10px !important;
    position: relative;
    left: 20%;
    bottom: 2%;
    font-size: 13px;
    border: 2px solid #386895;
  }
  .dash-view-button {
    margin-right: 16px !important;
    border-radius: 8px !important;
    padding: 5px 10px !important;
    color: #386895 !important;
    background-color: transparent !important;
    border: none;
    position: relative;
    left: 30%;
    bottom: 2%;
    font-weight: 500;
    font-size: 13px;
  }
}

/* .SideDrawer {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: white;
    padding: 32px 16px;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
}

@media (min-width: 500px) {
    .SideDrawer {
        display: none;
    }
}

.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(-100%);
}

.Logo {
    height: 11%;
    margin-bottom: 32px;
} */
@media (max-width: 992px) {
  .side-drawer {
    height: 100%;
    background: rgba(245, 241, 241, 0.925);
    position: fixed;
    box-shadow: 2px 0px 5px rgba(245, 241, 241, 0.925);
    position: fixed;
    top: 0;
    left: 0;
    width: 30%;
    max-width: 50%;
    z-index: 200;
  }
  .side-drawer #sidemenu {
    display: inline-block;
  }
  .side-drawer #sidemenu .sidemenu-ul {
    display: inline-block;
  }
  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 251, 251, 0.733);
    z-index: 100;
  }
  .toggle-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    background: transparent;
    border: none;
    padding: 30px;
    box-sizing: border-box;
    height: 88px;
    width: 140px;
    position: relative;
    bottom: 4px;
  }
  .toggle-button:focus {
    outline: none;
  }
  .toggle-button__line {
    width: 40%;
    height: 2px;
    background: #ce6464;
    margin-top: 3px;
    margin-left: 18%;
  }
  .sidemenu-ul {
    display: none;
  }
  #sidemenu-col {
    display: none;
  }
  #app-body {
    min-width: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  #header-row {
    width: 100%;
  }
  #header-logo {
    position: relative;
    max-width: 80%;
    max-height: 50%;
    top: 19px;
    left: 5%;
    float: none;
    margin: 0;
  }
}

@media (max-width: 992px) {
  .side-drawer {
    height: 100%;
    background: rgba(245, 241, 241, 0.925);
    position: fixed;
    box-shadow: 2px 0px 5px rgba(245, 241, 241, 0.925);
    position: fixed;
    top: 0;
    left: 0;
    width: 30%;
    max-width: 50%;
    z-index: 200;
  }
  .side-drawer #sidemenu {
    display: inline-block;
  }
  .side-drawer #sidemenu .sidemenu-ul {
    display: inline-block;
  }
  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 251, 251, 0.733);
    z-index: 100;
  }
  .toggle-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    background: transparent;
    border: none;
    padding: 30px;
    box-sizing: border-box;
    height: 88px;
    width: 140px;
    position: relative;
    bottom: 4px;
  }
  .toggle-button:focus {
    outline: none;
  }
  .toggle-button__line {
    width: 40%;
    height: 2px;
    background: #ce6464;
    margin-top: 3px;
    margin-left: 18%;
  }
  .sidemenu-ul {
    display: none;
  }
  #sidemenu-col {
    display: none;
  }
  #app-body {
    min-width: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  #header-row {
    width: 100%;
  }
  #header-logo {
    position: relative;
    max-width: 80%;
    max-height: 50%;
    top: 19px;
    left: 5%;
    float: none;
    margin: 0;
  }
}

@media (min-width: 992px) {
  .toggle-button {
    display: none;
  }
  .toggle-button__line {
    display: none;
  }
  .side-drawer {
    display: none;
  }
}

.grid-container-booking {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1.2fr;
      grid-template-columns: 1fr 1.2fr;
  -ms-grid-rows: 0.2fr 1.8fr 1fr;
      grid-template-rows: 0.2fr 1.8fr 1fr;
  grid-gap: 10px 30px;
  gap: 10px 30px;
      grid-template-areas: "new-booking-title ." "Booking-form Booking-calendar" "Booking-form Booking-calendar";
}

.Booking-form {
  -ms-grid-row: 2;
  -ms-grid-row-span: 2;
  -ms-grid-column: 1;
  grid-area: Booking-form;
}

.Booking-calendar {
  -ms-grid-row: 2;
  -ms-grid-row-span: 2;
  -ms-grid-column: 2;
  grid-area: Booking-calendar;
}

.new-booking-title {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: new-booking-title;
}

.new-booking-title .title {
  font-weight: 600;
  color: #454f63;
}

.white-background-bookings {
  background-color: #fff;
  width: 90%;
  height: 80%;
  border-radius: 15px;
  box-shadow: 8px 8px 8px #178bc616;
  padding: 2%;
  margin-bottom: 2%;
  margin-left: 5%;
}

.button-submit-booking {
  text-align: center;
}

#new-booking-button {
  margin-left: -10%;
}

.bookings {
  margin-left: -8%;
}

.bookings-title {
  margin-left: -10%;
}

#white-background {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 8px 8px 8px #178bc616;
  padding: 2%;
}

.booking-list {
  margin-left: 2%;
}

.h3-booking {
  margin-top: -50px;
  margin-bottom: 20px;
  margin-left: 90px;
  color: #454f63;
  font-weight: 400;
}

.bookings {
  color: #454f63;
  font-size: 23px;
  margin-bottom: 2%;
  margin-top: -2%;
}

.label-new-booking {
  color: #454f63;
  font-weight: 600;
  font-size: 15px;
  margin-top: 8px;
  white-space: nowrap;
  letter-spacing: 0.94px !important;
}

.pick-date {
  padding-bottom: 20px;
  text-align: center;
  font-weight: 500;
  color: #454f63;
}

.rdt_TableRow {
  color: black !important;
  background-color: #fff !important;
}

.rdt_TableCell {
  background-color: #fff !important;
}

.calendar-border {
  border: 1px solid #CECECE;
  border-radius: 10px;
  opacity: 1;
  padding: 2px;
  margin-top: -16px;
}

@media only screen and (max-width: 600px) {
  .grid-container-booking {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -ms-grid-rows: 0.1fr 1fr 1fr;
        grid-template-rows: 0.1fr 1fr 1fr;
    grid-gap: 10px 10px;
    gap: 10px 10px;
        grid-template-areas: "new-booking-title" "Booking-calendar" "Booking-form";
  }
  .Booking-form {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    grid-area: Booking-form;
  }
  .Booking-calendar {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    grid-area: Booking-calendar;
    max-width: 90px;
    width: 90px;
  }
  .calendar-border {
    border: none !important;
  }
  .rdrCalendarWrapper {
    color: #000000;
    width: 50% !important;
  }
  .rdrStaticRangeLabel {
    display: none !important;
  }
  .rdrInputRange {
    display: none !important;
    width: 190% !important;
  }
  .rdrInputRange .calendar-border {
    border: none !important;
  }
  .rdrInputRange .rdrCalendarWrapper {
    color: #000000;
    width: 50% !important;
  }
  .rdrInputRange .rdrStaticRangeLabel {
    display: none !important;
  }
  .rdrInputRange .rdrInputRange {
    display: none !important;
  }
  .rdrInputRange .rdrDefinedRangesWrapper {
    font-size: 12px;
    width: 0px !important;
    border-right: solid 1px #eff2f7;
    background: #fff;
  }
  .rdrInputRange .rdrDateDisplayWrapper {
    width: 190% !important;
  }
  .rdrInputRange .pick-date {
    white-space: nowrap !important;
  }
  .rdrInputRange .rdrDateRangePickerWrapper {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-right: 20px !important;
  }
  .pick-date {
    white-space: nowrap !important;
  }
  .rdrDateRangePickerWrapper {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-right: 20px !important;
  }
  .rdrDefinedRangesWrapper {
    font-size: 12px;
    width: 0px !important;
    border-right: solid 1px #eff2f7;
    background: #fff;
  }
  .custom-btn-cancel {
    background: transparent;
    color: #656C78;
    border: 1px solid #386895;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 15px;
    width: 150px;
    margin-top: 3px;
    height: 38px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 767px) {
  .grid-container-booking {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 0.7fr 1.1fr;
        grid-template-columns: 0.7fr 1.1fr;
    -ms-grid-rows: 0.2fr 1fr 1fr;
        grid-template-rows: 0.2fr 1fr 1fr;
    grid-gap: 10px 10px;
    gap: 10px 10px;
        grid-template-areas: "new-booking-title ." "Booking-form Booking-calendar" "Booking-form Booking-calendar";
  }
  .Booking-form {
    -ms-grid-row: 2;
    -ms-grid-row-span: 2;
    -ms-grid-column: 1;
    grid-area: Booking-form;
  }
  .Booking-calendar {
    -ms-grid-row: 2;
    -ms-grid-row-span: 2;
    -ms-grid-column: 2;
    grid-area: Booking-calendar;
    max-width: 90px;
    width: 90px;
  }
  .calendar-border {
    border: none !important;
  }
  .rdrCalendarWrapper {
    color: #000000;
    width: 50% !important;
  }
  .rdrStaticRangeLabel {
    display: none !important;
  }
  .rdrInputRange {
    display: none !important;
  }
  .rdrDefinedRangesWrapper {
    font-size: 12px;
    width: 0px !important;
    border-right: solid 1px #eff2f7;
    background: #fff;
  }
  .rdrDateDisplayWrapper {
    width: 190% !important;
  }
  .pick-date {
    white-space: nowrap !important;
  }
  .rdrDateRangePickerWrapper {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-right: 20px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .grid-container-booking {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 0.7fr 1.1fr;
        grid-template-columns: 0.7fr 1.1fr;
    -ms-grid-rows: 0.2fr 1fr 1fr;
        grid-template-rows: 0.2fr 1fr 1fr;
    grid-gap: 10px 10px;
    gap: 10px 10px;
        grid-template-areas: "new-booking-title ." "Booking-form Booking-calendar" "Booking-form Booking-calendar";
  }
  .Booking-form {
    -ms-grid-row: 2;
    -ms-grid-row-span: 2;
    -ms-grid-column: 1;
    grid-area: Booking-form;
  }
  .Booking-calendar {
    -ms-grid-row: 2;
    -ms-grid-row-span: 2;
    -ms-grid-column: 2;
    grid-area: Booking-calendar;
    max-width: 90px;
    width: 90px;
  }
  .calendar-border {
    border: none !important;
  }
  .rdrCalendarWrapper {
    color: #000000;
    width: 50% !important;
  }
  .rdrStaticRangeLabel {
    display: none !important;
  }
  .rdrInputRange {
    display: none !important;
  }
  .rdrDefinedRangesWrapper {
    font-size: 12px;
    width: 0px !important;
    border-right: solid 1px #eff2f7;
    background: #fff;
  }
  .rdrDateDisplayWrapper {
    width: 190% !important;
  }
  .pick-date {
    white-space: nowrap !important;
  }
  .rdrDateRangePickerWrapper {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-right: 20px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .calendar-border {
    border: none !important;
  }
  .rdrCalendarWrapper {
    color: #000000;
    width: 50% !important;
  }
  .rdrStaticRangeLabel {
    display: none !important;
  }
  .rdrInputRange {
    display: none !important;
  }
  .rdrDefinedRangesWrapper {
    font-size: 12px;
    width: 0px !important;
    border-right: solid 1px #eff2f7;
    background: #fff;
  }
  .rdrDateDisplayWrapper {
    width: 190% !important;
  }
  .pick-date {
    white-space: nowrap !important;
  }
  .rdrDateRangePickerWrapper {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-right: 20px !important;
  }
}

.grid-container-tech {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1.2fr;
      grid-template-columns: 1fr 1.2fr;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
  grid-gap: 1px 1px;
  gap: 1px 1px;
      grid-template-areas: "form map-tech" "buttons buttons";
  background-color: white;
  padding: 2%;
  border-radius: 15px;
  margin: 0px 50px 30px 50px;
  box-shadow: 8px 8px 8px #178bc616;
}

.tech-form-buttons {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: buttons;
  margin-left: auto;
  margin-right: auto;
  width: auto !important;
}

.form {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: form;
}

.map-tech {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: map-tech;
  height: 52vh;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-left: 15px;
}

.map-form {
  padding-right: 10px;
  margin-right: 30px;
  width: 100%;
}

.tech-header {
  color: #454f63;
  margin-bottom: 15px;
}

@media only screen and (min-width: 765px) and (max-width: 1100px) {
  .tech-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .form-row {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
}

.map-dashboard {
  height: 52vh;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}

.map-size {
  padding-left: 15px;
}

@media only screen and (max-width: 599px) {
  .grid-container-tech {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -ms-grid-rows: 1fr;
        grid-template-rows: 1fr;
    grid-gap: 1px 1px;
    gap: 1px 1px;
        grid-template-areas: "map-tech" "form" "buttons";
    background-color: white;
    padding: 2%;
    border-radius: 15px;
    box-shadow: 8px 8px 8px #178bc616;
  }
  .form {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    grid-area: form;
    width: 95%;
  }
  .map-tech {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: map-tech;
  }
  .map-tech {
    height: 21vh;
    width: 98%;
    padding: 0;
    margin: 0;
    height: 40% !important;
  }
  .map-size {
    height: 42vh;
    margin-top: 10px;
    border-radius: 15px;
    border-radius: 10px !important;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .grid-container-tech {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -ms-grid-rows: 1fr;
        grid-template-rows: 1fr;
    grid-gap: 1px 1px;
    gap: 1px 1px;
        grid-template-areas: "map-tech" "form" "buttons";
    background-color: white;
    padding: 2%;
    border-radius: 15px;
    box-shadow: 8px 8px 8px #178bc616;
  }
  .form {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    grid-area: form;
    width: 95%;
  }
  .map-tech {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: map-tech;
  }
  .map-tech {
    height: 22vh;
    width: 98%;
    padding: 0;
    margin: 0;
    height: 40% !important;
  }
  .map-size {
    height: 42vh;
    margin-top: 10px;
    border-radius: 15px;
    border-radius: 10px !important;
    margin-bottom: 10px;
  }
  .custom-form label {
    font-weight: 600 !important;
    font-size: 13px;
    color: #454f63;
    opacity: 1;
  }
  .map-dashboard {
    height: 37vh;
    border-radius: 15px;
    overflow: hidden;
  }
  .form-button-wrapper-tech {
    margin-left: -120px !important;
  }
  .checkboxes-rows {
    height: 100px;
  }
  .checkbox-row {
    white-space: nowrap;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grid-container-tech {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -ms-grid-rows: 1fr;
        grid-template-rows: 1fr;
    grid-gap: 1px 1px;
    gap: 1px 1px;
        grid-template-areas: "map-tech" "form" "buttons";
    background-color: white;
    border-radius: 15px;
    box-shadow: 8px 8px 8px #178bc616;
  }
  .form {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    grid-area: form;
  }
  .map-tech {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: map-tech;
  }
  .map-tech {
    height: 22vh;
    width: 98%;
    padding: 0;
    margin: 0;
    height: 40% !important;
  }
  .map-size {
    height: 35vh;
    margin-top: 10px;
    border-radius: 15px;
    border-radius: 10px !important;
    margin-bottom: 10px;
  }
  .custom-form label {
    font-weight: 600 !important;
    font-size: 13px;
    color: #454f63;
    opacity: 1;
    max-width: none;
  }
  .map-dashboard {
    height: 37vh;
    border-radius: 15px;
    overflow: hidden;
  }
  .form-button-wrapper-tech {
    margin-left: -340px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1101px) {
  .grid-container-tech {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -ms-grid-rows: 1fr;
        grid-template-rows: 1fr;
    grid-gap: 1px 1px;
    gap: 1px 1px;
        grid-template-areas: "map-tech" "form" "buttons";
    background-color: white;
    padding: 2%;
    border-radius: 15px;
    box-shadow: 8px 8px 8px #178bc616;
  }
  .form {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    grid-area: form;
  }
  .map-tech {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: map-tech;
  }
  .map-tech {
    height: 22vh;
    width: 95%;
    padding: 0;
    margin: 0;
    margin-left: 15px;
    height: 40% !important;
  }
  .map-dashboard {
    height: 36vh;
    border-radius: 15px;
    overflow: hidden;
  }
  .map-size {
    height: 42vh;
    margin-top: 10px;
    border-radius: 15px;
    border-radius: 10px !important;
    margin-bottom: 10px;
  }
  .custom-form label {
    font-weight: 600 !important;
    font-size: 13px;
    color: #454f63;
    opacity: 1;
  }
  .form-button-wrapper-tech {
    margin-left: -340px !important;
  }
}

@media only screen and (min-width: 1101px) and (max-width: 1180px) {
  .map-size {
    height: 400px;
    min-width: 440px;
    margin-top: 65px;
    border-radius: 15px;
    border-radius: 10px !important;
  }
  .map-tech {
    width: 100%;
    padding: 0;
    margin: 0;
    margin-left: 15px;
  }
  .form-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
    margin-bottom: -7px !important;
  }
}

@media (min-width: 1181px) {
  .map-size {
    height: 400px;
    min-width: 460px;
    margin-top: 65px;
    border-radius: 15px;
    border-radius: 10px !important;
  }
  .map-tech {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .form-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
    margin-bottom: -7px !important;
  }
}

#register-button {
  margin-bottom: -35px;
  margin-left: -12em;
}

.white-background-customers {
  background-color: white;
  padding: 3%;
  border-radius: 12px;
  margin: 40px;
  position: relative;
  margin-top: 21px;
  box-shadow: 8px 8px 8px #178bc616;
  max-height: 550px;
  min-height: 400px;
}

.customer-list-registration-title {
  margin-top: 15px;
  margin-bottom: 15px;
}

.customer-list-registration-title span {
  font-weight: 500;
  color: #454F63;
}

.pending-customers-button {
  position: absolute;
  top: -25px;
  right: -25px;
  background-color: #202f81;
  border: none;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  color: white;
}

.pending-customers-count {
  position: absolute;
  top: -5px;
  right: -1px;
  background-color: red;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-width: 20px;
  font-size: 11px;
  border-radius: 100%;
  padding: 1px;
}

.registration-drawer-paper {
  padding-top: 70px;
  width: 300px;
  background-color: #dee7f0 !important;
}

.customer-list-registration-customer {
  padding: 15px;
  border-radius: 15px !important;
  margin-top: 15px;
  margin-bottom: 15px;
}

.customer-list-registration-username {
  color: #454F63;
  font-weight: bold;
  letter-spacing: 0.94px;
  font-size: 16px;
  font-family: "Roboto";
}

.customer-list-registration-button-container {
  margin-top: 15px;
  margin-bottom: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.customer-list-registration-button-container button:first-child {
  margin-right: 10px;
}

.customer-list-registration-button-container button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-left: 15px;
  padding-right: 15px;
}

.customer-list-registration-details {
  color: #454F63;
  margin-bottom: 0px !important;
  font-family: "Roboto";
  font-size: 13px;
}

.customer-list-registration-details span {
  color: #454F63;
  opacity: 0.54;
  font-family: "Roboto";
  font-size: 13px;
}

button.registration-drawer-button {
  background-color: white;
  border-radius: 10px;
  padding: 5px;
  color: #202F81;
}

.registration-drawer-content {
  padding: 15px;
}

.created_date {
  margin-left: 10px;
  color: #D2D4DA;
  font-size: 10px;
  font-weight: normal;
}

input[type="file" i] {
  -webkit-appearance: initial;
          appearance: initial;
  background-color: transparent;
  background-color: initial;
  cursor: default;
  color: inherit;
  text-overflow: ellipsis;
  text-align: start !important;
  padding: 3px;
  overflow: hidden;
}

@media (max-width: 599px) {
  #register-button {
    margin-bottom: -35px;
    margin-left: -5.5em;
    font-size: 12px;
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  #register-button {
    margin-bottom: -35px;
    margin-left: -7em;
    font-size: 12px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #register-button {
    margin-bottom: -35px;
    margin-left: -8em;
    font-size: 13px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #register-button {
    margin-bottom: -35px;
    margin-left: -9em;
    font-size: 13px;
  }
}

@media only screen and (min-width: 1430px) {
  #register-button {
    margin-bottom: -35px;
    margin-left: -11.5em;
    font-size: 13px;
  }
}

@media only screen and (min-width: 1660px) {
  #register-button {
    margin-bottom: -35px;
    margin-left: -16em;
    font-size: 13px;
  }
}

#preview-img-container {
  display: block;
  position: relative;
  width: 50%;
}

#preview-img-close {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 22px;
  height: 22px;
  color: white;
  background: #787878;
  border-radius: 50%;
  border: 3px solid #ffffff;
}

#preview-img-close:hover {
  cursor: pointer;
}

.publish-offer-btn {
  background: #386895;
  color: #EFF6FF;
  border: 2px solid #386895;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 14px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  width: 15%;
  margin-left: 33%;
}

#news-offers-btns {
  display: -webkit-inline-flex !important;
  display: inline-flex !important;
  -webkit-justify-content: center !important;
          justify-content: center !important;
  -webkit-align-items: center !important;
          align-items: center !important;
}

#news-offers-btns button {
  height: 38px;
  margin-left: 0px !important;
}

.custom-btn-cancel-modal {
  background: transparent;
  color: #656C78;
  border: 1px solid #386895;
  border-radius: 10px;
  font-size: 13px;
  width: 90px;
  float: right !important;
  height: 30px !important;
  margin-left: 12px;
}

@-moz-document url-prefix() {
  #image-url-input {
    width: 150px;
    height: 30px;
  }
  .or-text {
    margin-left: 30px !important;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    margin-top: 5px;
  }
  #file-upload {
    width: 250px;
    margin-right: -30px;
    font-size: 14px;
  }
}

.add-img-btn {
  background: #386895;
  color: #EFF6FF;
  border: 2px solid #386895;
  border-radius: 10px;
  padding: 3px 7px;
  font-size: 14px;
  width: 70px;
  margin-left: 2%;
  float: right;
}

.white-background-offers {
  width: 90%;
  background-color: white;
  padding: 30px;
  margin-left: 25px;
  box-shadow: 8px 8px 8px #178BC616;
  border-radius: 15px;
  margin-bottom: 40px;
}

.labels-offers {
  color: #454F63;
  font-size: 14px;
  font-weight: 550;
  letter-spacing: 0.94px !important;
  margin-bottom: 12px;
}

.imgPreviewPost {
  text-align: center;
  margin-right: 30px;
  margin-top: 32px;
  height: 222px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
}

.imgPreviewPost img {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 222px;
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #ced4da;
  border-radius: 10px;
}

.or-text {
  margin-left: -110px;
  margin-right: 15px;
}

.imgPreview {
  text-align: center;
  margin-right: 30px;
  margin-top: 31px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
}

.imgPreview img {
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #ced4da;
  border-radius: 10px;
}

#formGridTextPost {
  height: 226px !important;
}

#formGridUrl {
  width: 230px !important;
  height: 32px !important;
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .imgPreview img {
    max-width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #ced4da;
    border-radius: 10px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .publish-offer-btn {
    background: #386895;
    color: #EFF6FF;
    border: 2px solid #386895;
    border-radius: 10px;
    font-size: 14px;
    width: 20%;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    margin-left: 24%;
    white-space: nowrap;
  }
  .imgPreview img {
    max-width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #ced4da;
    border-radius: 10px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .imgPreview img {
    max-width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #ced4da;
    border-radius: 10px;
  }
  #formGridDiscount {
    margin-right: 230px;
    margin-top: -0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1599) {
  .white-background-offers {
    width: 120% !important;
    background-color: white;
    padding: 30px;
    margin-left: 55px;
    box-shadow: 8px 8px 8px #178BC616;
    border-radius: 15px;
    margin-bottom: 40px;
  }
  #preview-image {
    height: 320px;
    width: 100%;
  }
}

@media only screen and (min-width: 1600px) {
  .white-background-offers {
    background-color: white;
    padding: 30px;
    margin-left: 25px;
    box-shadow: 8px 8px 8px #178BC616;
    border-radius: 15px;
    margin-bottom: 40px;
  }
  #formGridTextPost {
    width: 100% !important;
    height: 230px !important;
  }
  .publish-offer-btn {
    background: #386895;
    color: #EFF6FF;
    border: 2px solid #386895;
    border-radius: 10px;
    font-size: 14px;
    width: 12%;
    margin-left: 35%;
  }
}

.grid-container-lists {
  margin-top: 15px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
      grid-template-areas: "news-list offers-list";
}

.news-list {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: news-list;
  max-width: 350px;
}

.offers-list {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: offers-list;
  margin-left: -1%;
}

.header-lists-news-off {
  margin-top: -35px;
  margin-left: 6%;
  color: #454F63;
  font-weight: 400;
}

#fonticon-list-news-off {
  margin-left: 4%;
  margin-bottom: 8px;
}

.white-background-offers {
  background-color: #fff;
  padding: 30px;
  margin-left: 25px;
  box-shadow: 8px 8px 8px #178BC616;
  border-radius: 15px;
  margin-bottom: 40px;
}

.white-background-offers-list {
  width: 510px;
  background-color: #fff;
  padding: 20px;
  margin-left: 37px;
  box-shadow: 8px 8px 8px #178BC616;
  border-radius: 15px;
  margin-bottom: 40px;
}

@media (min-width: 601px) and (max-width: 767px) {
  .grid-container-lists {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -ms-grid-rows: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: "news-list offers-list";
  }
  .news-list {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: news-list;
    max-width: 370px;
  }
  .offers-list {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    grid-area: offers-list;
    margin-left: -1%;
  }
  .header-lists-news-off {
    margin-top: -35px;
    margin-left: 6%;
    color: #454F63;
    font-weight: 400;
  }
  #fonticon-list-news-off {
    margin-left: 3%;
  }
  .white-background-offers {
    background-color: #fff;
    padding: 30px;
    margin-left: 25px;
    box-shadow: 8px 8px 8px #178BC616;
    border-radius: 15px;
    margin-bottom: 40px;
  }
  .white-background-offers-list {
    width: 480px;
    background-color: #fff;
    padding: 20px;
    margin-left: 25px;
    box-shadow: 8px 8px 8px #178BC616;
    border-radius: 15px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 599px) {
  .grid-container-lists {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -ms-grid-rows: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: "news-list" "offers-list";
  }
  .news-list {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: news-list;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    justify-self: center;
  }
  .offers-list {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    grid-area: offers-list;
    margin-left: -1%;
    margin-left: auto;
    margin-right: auto;
  }
  .header-lists-news-off {
    margin-left: 12%;
    color: #454F63;
    font-weight: 400;
    font-size: 24px;
  }
  #fonticon-list-news-off {
    margin-left: 8%;
    margin-top: 20px !important;
  }
  .white-background-offers {
    width: 80vw;
    background-color: #fff;
    padding: 30px;
    margin-left: 50px;
    box-shadow: 8px 8px 8px #178BC616;
    border-radius: 15px;
    margin-bottom: 40px;
  }
  .white-background-offers-list {
    width: 90vw;
    background-color: #fff;
    padding: 30px;
    margin-right: 25px;
    box-shadow: 8px 8px 8px #178BC616;
    border-radius: 15px;
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .grid-container-lists {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -ms-grid-rows: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: "news-list" "offers-list";
    margin-left: 30px;
  }
  .news-list {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: news-list;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    justify-self: center;
  }
  .offers-list {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    grid-area: offers-list;
    margin-left: -1%;
    margin-left: auto;
    margin-right: auto;
  }
  .header-lists-news-off {
    margin-left: 10%;
    color: #454F63;
    font-weight: 400;
    font-size: 24px;
  }
  #fonticon-list-news-off {
    margin-left: 8%;
    margin-top: 20px !important;
  }
  .white-background-offers {
    width: 80vw;
    background-color: white;
    padding: 30px;
    margin-left: 50px;
    box-shadow: 8px 8px 8px #178BC616;
    border-radius: 15px;
    margin-bottom: 40px;
  }
  .white-background-offers-list {
    width: 480px;
    background-color: #fff;
    padding: 30px;
    margin-left: 25px;
    margin-right: 25px;
    box-shadow: 8px 8px 8px #178BC616;
    border-radius: 15px;
    margin-bottom: 40px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .grid-container-lists {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -ms-grid-rows: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: "news-list" "offers-list";
    margin-left: 30px;
  }
  .news-list {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: news-list;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    justify-self: center;
  }
  .offers-list {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    grid-area: offers-list;
    margin-left: -1%;
    margin-left: auto;
    margin-right: auto;
  }
  .header-lists-news-off {
    margin-left: 10%;
    color: #454F63;
    font-weight: 400;
    font-size: 24px;
  }
  #fonticon-list-news-off {
    margin-left: 8%;
    margin-top: 20px !important;
  }
  .white-background-offers {
    background-color: #fff;
    padding: 30px;
    margin-left: 50px;
    box-shadow: 8px 8px 8px #178BC616;
    border-radius: 15px;
    margin-bottom: 40px;
  }
  .white-background-offers-list {
    width: 620px;
    background-color: #fff;
    padding: 30px;
    margin-left: 25px;
    box-shadow: 8px 8px 8px #178BC616;
    border-radius: 15px;
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1190px) {
  .grid-container-lists {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    -ms-grid-rows: 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: "news-list offers-list";
  }
  .news-list {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: news-list;
    max-width: 100px;
  }
  .offers-list {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    grid-area: offers-list;
    max-width: 170px;
    margin-left: -1%;
  }
  .white-background-offers {
    background-color: #fff;
    padding: 30px;
    margin-left: 25px;
    box-shadow: 8px 8px 8px #178BC616;
    border-radius: 15px;
    margin-bottom: 40px;
  }
  .white-background-offers-list {
    width: 390px;
    background-color: #fff;
    padding: 30px;
    margin-left: 25px;
    box-shadow: 8px 8px 8px #178BC616;
    border-radius: 15px;
    margin-bottom: 40px;
  }
  .header-lists-news-off {
    margin-top: -35px;
    margin-left: 10%;
    color: #454F63;
    font-weight: 400;
  }
  #fonticon-list-news-off {
    margin-left: 6%;
  }
}

@media only screen and (min-width: 1191px) {
  .white-background-offers-list {
    width: 440px;
  }
}

@media only screen and (min-width: 1250px) {
  .white-background-offers-list {
    width: 490px;
  }
}

@media only screen and (min-width: 1390px) {
  .white-background-offers-list {
    width: 530px;
  }
}

@media only screen and (min-width: 1490px) {
  .white-background-offers-list {
    width: 560px;
  }
}

@media only screen and (min-width: 1640px) {
  .white-background-offers-list {
    width: 600px;
  }
}

@media only screen and (min-width: 1740px) {
  .white-background-offers-list {
    width: 680px;
  }
}

@media only screen and (min-width: 1990px) {
  .white-background-offers-list {
    width: 780px;
  }
}

* {
  box-sizing: border-box;
  font-family: Montserrat;
}

.rootHeading {
  text-align: center;
}

td {
  word-wrap: break-word;
}

html {
  height: 100%;
}

body {
  height: 100%;
  background-color: #F8F8F8 !important;
}

#root {
  min-height: 100%;
}

.body-content {
  background-color: #f8f8f8;
  padding-top: 3%;
}

.body-content-inner {
  display: block;
  margin: 0 auto;
}

#app-container,
#app-row-content {
  min-height: 100vh;
}

#login {
  margin: auto;
  background-color: white;
  border-radius: 16px;
  display: block;
  padding: 45px 80px;
  max-width: 400px;
  padding-bottom: 50px;
  box-shadow: 8px 8px 8px #178bc619;
  max-height: 450px;
  margin-top: -2%;
}

.remember-wrapper {
  display: inline-block;
}

.remember-wrapper label {
  padding-left: 10px;
  font-family: Montserrat;
  color: #4b5a69;
  font-size: 13px;
}

.fp-link {
  float: right;
  color: #386895;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 13px;
  opacity: 0.8;
}

#login-container {
  margin-top: 120px;
}

#weatherTable td {
  padding: 0px;
}

#weatherTable {
  margin-top: 3%;
}

#keypad {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 5px;
}

#keypadReset {
  margin-top: 7%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

#keypad-container {
  margin-top: 2%;
}

.select-image-button-container {
  border: 1px solid #ced4da;
  border-radius: 6px;
}

button.select-image-button {
  display: block;
  padding: 1px 12px !important;
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #DCDCDC 100%) 0% 0% no-repeat padding-box;
  border: 2px solid #ECECEC !important;
  color: #333333 !important;
}

#errorModal {
  margin-top: 20%;
}

#errorModal #errorModalTitle {
  color: #db3939;
  text-transform: lowercase;
  display: inline-block;
  font-size: 20px;
}

#errorModal #errorModalTitle::first-line {
  text-transform: capitalize;
}

#errorModal #errorModalBody {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
}

#errorModal #errorModalFooter {
  margin: auto;
}

.col-half-offset {
  margin-left: 3.7%;
}

.clearCSS {
  background: transparent;
  border-bottom-left-radius: 15px;
  color: #386895;
}

.login-header {
  font: 700 40px/63px Montserrat !important;
  letter-spacing: 0px;
  color: #386895;
  margin-top: -30px;
}

label.form-field-label {
  color: #386895;
  font-family: Montserrat;
  font-weight: 600;
}

input.form-control.form-control-lg.form-input-field {
  background: #f1f3f5 0% 0% no-repeat padding-box;
  border-radius: 13px !important;
  border: none;
}

hea input.form-control.form-control-lg.form-input-field::-webkit-input-placeholder {
  color: #9ea8b3;
  font-family: Montserrat;
  font-size: 10px;
}

input.form-control.form-control-lg.form-input-field:-moz-placeholder {
  color: #9ea8b3;
  font-family: Montserrat;
  font-size: 10px;
}

input.form-control.form-control-lg.form-input-field::-moz-placeholder {
  color: #9ea8b3;
  font-family: Montserrat;
  font-size: 10px;
}

input.form-control.form-control-lg.form-input-field:-ms-input-placeholder {
  color: #9ea8b3;
  font-family: Montserrat;
  font-size: 10px;
}

.form-control {
  font-size: 13px !important;
  border-radius: 6px !important;
}

input.login-button {
  background: #386895 0% 0% no-repeat padding-box !important;
  box-shadow: 5px 5px 8px #22232416;
  border-radius: 12px;
  max-height: 45px;
  font-size: 1em;
  font-weight: 600;
  font-family: Montserrat;
  text-transform: capitalize;
}

.login-logo-container {
  background-color: #f7f6f6;
  position: absolute;
  top: 0;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  text-align: center;
  min-height: 242px;
  -webkit-justify-content: center;
          justify-content: center;
  color: #386895;
}

.copyright-wrapper {
  text-align: center;
  padding-top: 15px;
  color: #386895;
  font-family: Montserrat;
  font-weight: Regular;
  font-size: 14px;
}

.login-logo {
  width: 435px;
  -webkit-align-content: initial;
          align-content: initial;
  height: auto;
  max-height: 180px;
  text-align: center;
  margin-top: -20px;
}

.title-svg {
  margin-top: 10px;
}

.rdt_TableHeader {
  display: none !important;
}

.rdt_TableCol {
  font-size: 1.1em !important;
  font-family: Montserrat !important;
}

.rdt_TableHeadRow {
  border-bottom-style: none !important;
}

.jEDPQU {
  display: block !important;
}

.rdt_Pagination {
  border-top: none !important;
  color: #2c304c !important;
}

.table-wrapper {
  width: 100%;
}

.caret {
  display: none;
}

.rdt_TableRow {
  font-size: 13px !important;
  color: #343434 !important;
  font-family: Montserrat !important;
  background-color: white !important;
  border-bottom-color: #edf2f2 !important;
  border-radius: 10px;
  height: 60px;
}

.rdt_TableRow:nth-child(even) {
  background-color: #eff6ff !important;
}

button#dropdown-action-user-org {
  background-color: transparent !important;
  border-color: transparent;
  text-align: left;
  box-shadow: none;
  width: 100%;
  max-width: 34px;
  font-size: 19px;
  text-align: right;
  margin-top: -5px;
  padding: 0;
}

button#dropdown-action-user-org img {
  min-width: 16px;
}

button#dropdown-action-user-org:after {
  display: none;
}

button#dropdown-action-user-org .dropdown-menu {
  top: -23px !important;
}

.table-action-button {
  width: 30px;
  cursor: pointer;
}

.table-number {
  color: #478acb;
  font-size: 20px;
  font-family: Montserrat !important;
  font-weight: 500;
}

.table-sub-header {
  color: #478acb;
  font-size: 16px;
  font-family: Montserrat !important;
  font-weight: 500;
}

.table-delete {
  color: #b03541;
  text-decoration: underline;
  font-size: 13px;
}

.table-delete img {
  padding-right: 5px;
  max-width: 15px;
}

.table-pdf {
  color: #5b667c;
  text-decoration: underline;
  font-size: 13px;
}

.table-pdf img {
  padding-right: 5px;
  max-width: 15px;
}

.table-password {
  color: #f1851d;
  text-decoration: underline;
  font-size: 13px;
}

.table-password img {
  padding-right: 5px;
  max-width: 15px;
}

.edit-table-wrapper.dropdown-menu.show {
  width: 10px !important;
  float: right !important;
  min-width: -webkit-max-content;
  min-width: max-content;
  margin-right: 10px;
}

.table-edit {
  color: #5b667c;
  text-decoration: underline;
  font-size: 13px;
}

.table-edit img {
  padding-right: 5px;
  max-width: 15px;
  color: #7e8ca7;
}

.table-dispatch {
  color: #3ac916;
  text-decoration: underline;
  font-size: 13px;
}

.table-dispatch img {
  padding-right: 5px;
  max-width: 15px;
}

.main-heading {
  font-size: 1.7em;
  font-family: Montserrat !important;
  font-weight: 500 !important;
  color: #454F63;
  font-weight: bolder;
}

.form-button-wrapper {
  right: 0;
  bottom: 10px;
}

button#dropdown-action {
  background-color: transparent !important;
  text-align: left;
}

.select-dropdown {
  box-shadow: 8px 8px 8px #178bc616;
  border-radius: 12px !important;
  border: none !important;
  height: 46px !important;
  position: relative;
  -webkit-appearance: none;
          appearance: none;
}

hea .select-dropdown::-webkit-input-placeholder {
  color: #478acb;
  font-family: Montserrat;
  font-size: 12px;
}

.select-dropdown:-moz-placeholder {
  color: #478acb;
  font-family: Montserrat;
  font-size: 12px;
}

.select-dropdown::-moz-placeholder {
  color: #478acb;
  font-family: Montserrat;
  font-size: 12px;
}

.select-dropdown:-ms-input-placeholder {
  color: #478acb;
  font-family: Montserrat;
  font-size: 12px;
}

.select-dropdown:after {
  content: "▼";
  padding: 12px 8px;
  position: absolute;
  right: 10px;
  top: 0;
  z-index: 1;
  text-align: center;
  width: 10%;
  height: 100%;
  pointer-events: none;
}

.custom-input {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 8px #178bc616;
  border-radius: 12px !important;
  border: none !important;
}

hea .custom-input::-webkit-input-placeholder {
  color: #7b7f83;
  font-family: Montserrat;
  font-size: 12px;
}

.custom-input:-moz-placeholder {
  color: #7b7f83;
  font-family: Montserrat;
  font-size: 12px;
}

.custom-input::-moz-placeholder {
  color: #7b7f83;
  font-family: Montserrat;
  font-size: 12px;
}

.custom-input:-ms-input-placeholder {
  color: #7b7f83;
  font-family: Montserrat;
  font-size: 12px;
}

.custom-form label {
  text-align: left;
  font-weight: 600 !important;
  font-size: 13px;
  color: #454f63;
  opacity: 1;
  letter-spacing: 0.34px !important;
}

.required-text {
  margin-right: 60px;
  font: 300 15px/23px Montserrat;
  letter-spacing: 1.24px;
  color: #21509e;
}

.solid-button {
  background: #386895 0% 0% no-repeat padding-box !important;
  box-shadow: 5px 5px 8px #178bc616 !important;
  font-size: 14px !important;
  color: white !important;
  border-radius: 12px !important;
  text-transform: capitalize !important;
  opacity: 1;
  height: 38px;
  max-width: 165px;
  width: 165px;
  padding: unset !important;
}

.outline-button {
  box-shadow: 5px 5px 8px #178bc616 !important;
  border: 1px solid #316ea9 !important;
  border-radius: 12px !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  color: #0b61a4 !important;
  width: 150px;
  padding: unset !important;
  height: 35px;
  background-color: transparent !important;
}

canvas.chartjs-render-monitor {
  position: absolute;
  top: -80px;
}

.pie-wrapper {
  position: relative;
  display: block;
}

.pie-wrapper-dash {
  height: 199px;
}

.table-search-wrapper {
  position: relative;
}

input.table-search {
  float: right;
  right: 0px;
  z-index: 10;
  margin-top: -30px;
  margin-bottom: 24px;
  height: 35px;
  width: 28%;
  border-radius: 12px;
  border: none;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-size: 14px !important;
  border: 1px solid #ced4da;
}

hea input.table-search::-webkit-input-placeholder {
  color: #9b9a9a;
  font-family: Montserrat;
  font-size: 14px !important;
  padding-left: 10px;
}

input.table-search:-moz-placeholder {
  color: #9b9a9a;
  font-family: Montserrat;
  font-size: 14px !important;
  padding-left: 10px;
}

input.table-search::-moz-placeholder {
  color: #9b9a9a;
  font-family: Montserrat;
  font-size: 14px !important;
  padding-left: 10px;
}

input.table-search:-ms-input-placeholder {
  color: #9b9a9a;
  font-family: Montserrat;
  font-size: 14px !important;
  padding-left: 10px;
}

.header-form {
  margin-top: -20px;
  margin-bottom: 10px;
  margin-left: 3px;
  color: #454f63;
  font-weight: 500;
}

#terms {
  white-space: nowrap;
  width: 100px;
  max-width: 250px;
  overflow: hidden;
}

#terms:hover {
  overflow: visible;
}

.register-client-form {
  background-color: white;
  box-shadow: 8px 8px 8px #178bc616;
  border-radius: 15px;
  margin-left: 55px;
  margin-right: 55px;
}

#title {
  text-align: center;
  font-family: arial, sans-serif;
}

.reset-password {
  height: 15px;
  width: 15px;
}

#checkbox-col {
  text-align: center;
}

.btn {
  box-shadow: none !important;
}

.rdt_TableCol {
  font-size: 14px !important;
  color: #6d778b !important;
}

.cLiCmx {
  padding-left: 15px !important;
  padding-right: 15px !important;
  font-weight: 5000 !important;
}

.jHsXUi {
  white-space: nowrap !important;
}

.custom-btn {
  background: #386895;
  color: #eff6ff;
  border: 2px solid #386895;
  border-radius: 12px;
  padding: 7px 10px;
  font-size: 15px;
  width: 150px;
  height: 39px;
}

.custom-btn-cancel {
  background: transparent;
  color: #656C78;
  border: 1px solid #386895;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 15px;
  width: 150px;
  margin-left: 3px;
  height: 38px;
}

@media only screen and (max-width: 599px) {
  .register-client-form {
    background-color: white;
    box-shadow: 8px 8px 8px #178bc616;
    border-radius: 15px;
    margin-left: 25px;
    margin-right: 15px;
  }
  button#dropdown-action-user-org {
    background-color: transparent !important;
    border-color: transparent;
    text-align: left;
    box-shadow: none;
    width: 100%;
    max-width: 34px;
    font-size: 19px;
    text-align: right;
    margin-top: 17px;
    padding: 0;
  }
  button#dropdown-action-user-org img {
    min-width: 16px;
  }
  button#dropdown-action-user-org:after {
    display: none;
  }
  button#dropdown-action-user-org .dropdown-menu {
    top: -23px !important;
  }
  .form-button-wrapper {
    right: 0;
    bottom: 10px;
    left: -30px;
  }
  #login {
    display: block;
    padding: 40px 30px;
    max-width: 450px;
    min-width: 300px;
    padding-bottom: 40px;
    max-height: 450px;
    margin-top: -2%;
  }
  .header-lists {
    margin-top: -24px;
    margin-left: 13%;
    color: #454f63;
    font-weight: 400;
  }
  #fonticon-list {
    margin-left: 8%;
    margin-top: 15px;
  }
  .login-logo {
    max-width: 260px;
    width: 260px;
    -webkit-align-content: initial;
            align-content: initial;
    height: auto;
    max-height: 120px;
    text-align: center;
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  #fonticon-list {
    margin-left: 8%;
    margin-top: 15px;
  }
  .header-lists {
    margin-top: -22px;
    margin-left: 12%;
    color: #454f63;
    font-weight: 400;
  }
  .row-terms {
    margin-left: -3% !important;
    position: relative;
  }
  #login {
    display: block;
    padding: 40px 20px;
    max-width: 550px;
    padding-bottom: 60px;
    box-shadow: 8px 8px 8px #178bc619;
    max-height: 450px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  #fonticon-list {
    margin-left: 9%;
    margin-top: 30px;
  }
  #icon-font {
    margin-bottom: 22px;
  }
  .header-lists {
    margin-top: -20px;
    margin-bottom: 20px;
    margin-left: 14%;
    color: #454f63;
    font-weight: 400;
  }
  .row-terms {
    margin-left: -3% !important;
    white-space: pre-wrap !important;
    position: relative;
  }
  #header-logo {
    margin-left: auto !important;
    width: 290px !important;
    height: auto;
    margin-top: 50px;
    margin-bottom: -20px;
    z-index: 10000;
  }
  .register-client-form {
    background-color: white;
    box-shadow: 8px 8px 8px #178bc616;
    border-radius: 15px;
    margin-left: 55px;
    margin-right: 55px;
  }
  #login {
    margin: auto;
    background-color: white;
    border-radius: 16px;
    display: block;
    padding: 50px 30px;
    max-width: 520px;
    padding-bottom: 60px;
    box-shadow: 8px 8px 8px #178bc619;
    max-height: 450px;
  }
  .login-logo {
    max-width: 330px;
    width: 330px;
    -webkit-align-content: initial;
            align-content: initial;
    height: auto;
    max-height: 160px;
    text-align: center;
    margin-top: 1%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #fonticon-list {
    margin-bottom: 25px;
    margin-left: 6%;
    margin-top: -12px;
  }
  #icon-font {
    margin-bottom: 22px;
  }
  .header-lists {
    margin-top: -50px;
    margin-bottom: 20px;
    margin-left: 10%;
    color: #454f63;
    font-weight: 400;
  }
  #header-logo {
    margin-left: auto;
    width: 185px;
    height: auto;
    margin-top: 20px;
    margin-bottom: -20px;
    z-index: 10000;
  }
  .row-terms {
    margin-left: 0px !important;
    white-space: normal !important;
  }
}

@media only screen and (min-width: 1200px) {
  .one-row-terms {
    white-space: nowrap !important;
    margin-left: 100px;
  }
  .row-terms {
    white-space: nowrap !important;
    margin-left: 15%;
  }
}

@media only screen and (min-width: 1660px) {
  #custom-form {
    width: 108%;
    margin-left: -50px;
  }
  .row-terms {
    margin-left: 22% !important;
    white-space: nowrap !important;
  }
  #header-logo {
    margin-left: -6%;
    width: 290px;
    padding-bottom: 10px;
    height: auto;
    margin-top: -20px;
    margin-bottom: -90px;
    z-index: 10000;
  }
}

@media only screen and (min-width: 1760px) {
  #custom-form {
    width: 115%;
    margin-left: -100px;
  }
  #register-button {
    margin-bottom: -35px;
    margin-left: -17em;
    font-size: 13px;
  }
}

.inputContainer i {
  position: absolute;
}

.inputContainer {
  width: 100%;
  margin-bottom: 10px;
}

.icon {
  padding: 10px;
  color: #9aa4b5;
  width: 60px;
  margin-left: 50%;
}

.dElJQE {
  -webkit-flex: 0 0;
          flex: 0 0;
  max-width: 100%;
  min-width: 100px;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0px;
}

.rdt_TableCell {
  background-color: #fff !important;
}

.rdt_Pagination {
  border-top: none !important;
  color: #2c304c !important;
  margin-top: 30px !important;
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #386895 !important;
}

.bgXvAY div:first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px !important;
}

.dropdown, .dropleft, .dropright, .dropup {
  margin-bottom: 20px !important;
  position: relative;
}

.geKTIA {
  position: relative;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100%;
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
  min-height: 230px !important;
}

.jMvRrf {
  position: relative;
  width: 100%;
  border-radius: inherit;
  overflow: hidden !important;
  min-height: 0px;
}
/*# sourceMappingURL=main.css.map */
/* CSS rules to help patch things together.
   Ideally, this is an empty file.
*/

.force-block-display {
    display: block !important;
  }

.red-border-line {
    border: 1px #dc3545 solid;
    border-radius: 5px;
}
