/* CSS rules to help patch things together.
   Ideally, this is an empty file.
*/

.force-block-display {
    display: block !important;
  }

.red-border-line {
    border: 1px #dc3545 solid;
    border-radius: 5px;
}